/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";
import * as S from "./styles";
import {
  // WinnerFirstIcon,
  // WinnerSecondIcon,
  // WinnerThirdIcon,
  // WinnerFirstIconMobile,
  // WinnerSecondIconMobile,
  // WinnerThirdIconMobile,
  // InfoIcon,
  // Podium,
  // UpdatedPodium,
  // PodiumNew,
  // FirstPlaceTrophy,
  CloseIcon,
} from "../../../../assets/svg";
import { breakpoints } from "../../../../themes";
import useMedia from "use-media";
import { EPHERE_GAME_ID, KOINS_GAME_ID } from "../../../../constants";
import LeaderboardTemplateModal from "./LeaderboardTemplateModal";
import { GroupSelector } from "../GroupSelector/GroupSelector";
import { DynamicLeaderboard } from "./DynamicLeaderboard/DynamicLeaderboard";
import { useDebounce } from "../../../../hooks";
import ContactSection from "../ContactSection";
import BracketsPlayers from "../../../../views/BracketsPlayers/BracketsPlayers";
import { WinnersComponent } from "../WinnersComponent";
import KoinsLeaderboard from "./KoinsLeaderboard";
import LeaderBoardComponent from "./LeaderboardComponent";

function LeaderBoardActiveTab({
  activeFilter,
  leaderBoardInfo = [],
  isFinished,
  koinsLeaderboardHeaders,
  isMobile,
  gamePlatform,
  gameId,
  dynamicHeaders,
  joinMode,
  winnersPrize,
  selectPlayerForTemplate,
  hasTemplate,
  template,
  winnerSectionBg,
  searchTerm = "",
  poolStatus,
  poolId,
  groupIdSelected,
}) {
  const debouncedSearch = useDebounce(searchTerm, 200);
  const [filteredList, setFilteredList] = useState([]);
  useEffect(() => {
    if (!debouncedSearch) {
      setFilteredList(leaderBoardInfo);
      return;
    }
    setFilteredList(
      leaderBoardInfo?.filter(player =>
        player.item?.nick?.toLowerCase()?.includes(debouncedSearch?.toLowerCase()?.trim()),
      ),
    );
  }, [debouncedSearch]);

  useEffect(() => {
    if (!leaderBoardInfo?.length) {
      setFilteredList([]);
    }
    if (leaderBoardInfo && Array.isArray(leaderBoardInfo) && leaderBoardInfo.length > 0) {
      setFilteredList(leaderBoardInfo);
    }
  }, [leaderBoardInfo.length]); // using leaderboardInfo.length instead of leaderboardInfo to prevent unnecessary rerenders

  switch (activeFilter) {
    case "Players":
    case "Teams":
      return (
        <BracketsPlayers
          joinMode={joinMode}
          players={filteredList.map(listItem => ({ ...(listItem?.item || {}) }))}
          poolId={poolId}
          poolStatus={poolStatus}
        />
      );
    case "Winners":
      return (
        <WinnersComponent
          data={leaderBoardInfo}
          isFinished={isFinished}
          winnersPrize={winnersPrize}
          winnerSectionBg={winnerSectionBg}
        />
      );
    case "All":
    default:
      return (koinsLeaderboardHeaders && koinsLeaderboardHeaders?.length > 0) ||
        (dynamicHeaders && dynamicHeaders?.length > 0) ? (
        gameId == KOINS_GAME_ID ? (
          <KoinsLeaderboard
            koinsLeaderboardHeaders={koinsLeaderboardHeaders}
            leaderBoardInfo={filteredList}
            isMobile={isMobile}
            gamePlatform={gamePlatform}
            joinMode={joinMode}
            selectPlayerForTemplate={selectPlayerForTemplate}
            hasTemplate={hasTemplate}

          />
        ) : (
          <DynamicLeaderboard
            dynamicHeaders={dynamicHeaders}
            leaderBoardInfo={filteredList}
            isMobile={isMobile}
            gameId={gameId}
            joinMode={joinMode}
            selectPlayerForTemplate={selectPlayerForTemplate}
            hasTemplate={hasTemplate}
            groupIdSelected={groupIdSelected}
          />
        )
      ) : (
        <LeaderBoardComponent
          leaderBoardInfo={filteredList}
          isMobile={isMobile}
          gamePlatform={gamePlatform}
          joinMode={joinMode}
          selectPlayerForTemplate={selectPlayerForTemplate}
          hasTemplate={hasTemplate}
          groupIdSelected={groupIdSelected}
        />
      );
  }
}

export default function LeaderBoard({
  leaderBoardData,
  isFinished,
  isFinishedCollectingData,
  gamePlatform,
  koinsLeaderboardHeaders,
  gameId,
  dynamicHeaders,
  joinMode,
  winnersPrize,
  template,
  winnerSectionBg = "",
  status,
  poolType,
  organizerInfo = [],
  poolId,
  groupIdSelected,
}) {
  const isMobile = useMedia({ maxWidth: breakpoints.laptop });
  const leaderBoardInfo = leaderBoardData;
  const [filterArray, setFilterArray] = useState([
    "Leaderboard",
    joinMode !== "TEAM" ? "Players" : "Teams",
    ...(isFinished && winnersPrize?.length >= 1 ? ["Winners"] : []), // Adds Winner to the array if conditions are true
    "Contact Info",
  ]);
  const [activeFilter, setActiveFilter] = useState(poolType === "MULTI_STAGE" ? "Leaderboard" : "");
  const [showLeaderboardTemplate, setShowLeaderboardTemplate] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  function filterItems(item) {
    setActiveFilter(item);
  }

  function selectPlayerForTemplate(data) {
    setSelectedPlayer(data);
    setShowLeaderboardTemplate(true);
  }

  function toggleLeaderboardTemplate() {
    setShowLeaderboardTemplate(prev => !prev);
  }

  const handleClear = () => {
    setSearchTerm("");
  };

  useEffect(() => {
    if (poolType !== "MULTI_STAGE") {
      if (isFinished && winnersPrize?.length >= 1) {
        setActiveFilter("Winners");
      } else if (status === "JOINING" || status === "PROCESSING_REGISTERED_USERS" || status === "PENDING") {
        setActiveFilter(joinMode !== "TEAM" ? "Players" : "Teams");
      } else {
        setActiveFilter("Leaderboard");
      }
    }
    return;
  }, [isFinished]);

  return (
    <>
      <S.LeaderBoardContainer isMobile={isMobile}>
        <div>
          {poolType !== "MULTI_STAGE" && (
            <S.FilterOutterWrapper>
              <S.TopMenu>
                <S.FilterWrapper>
                  {filterArray.map(item => (
                    <Tooltip
                      title={item == "Contact Info" && organizerInfo?.length < 1 ? `Organizer info not available` : ""}
                      color="#7265C8"
                      overlayStyle={{
                        maxWidth: "fit-content",
                        fontSize: "11px",
                        padding: "11px 8px",
                        borderRadius: "6px",
                      }}
                    >
                      <S.FilterButton
                        active={activeFilter === item}
                        shouldDisable={item == "Contact Info" && (!organizerInfo || organizerInfo?.length < 1)}
                        key={item}
                        onClick={() => {
                          if (item === "Contact Info" && organizerInfo?.length < 1) return;
                          filterItems(item);
                        }}
                      >
                        {item}
                      </S.FilterButton>
                    </Tooltip>
                  ))}
                </S.FilterWrapper>
              </S.TopMenu>
              {activeFilter === "Leaderboard" || activeFilter === "Teams" ? (
                <S.PlayerSearchContainer>
                  <S.PlayerSearchInput
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target?.value)}
                    placeholder="Search"
                    type="search"
                  />
                  <S.ClearButton onClick={handleClear} visible={!!searchTerm}>
                    <CloseIcon />
                  </S.ClearButton>
                </S.PlayerSearchContainer>
              ) : null}
            </S.FilterOutterWrapper>
          )}
          {activeFilter === "Contact Info" ? (
            <ContactSection organizerInfo={organizerInfo} />
          ) : (
            <>
              {/* <GroupSelector /> */}
              <LeaderBoardActiveTab
                activeFilter={activeFilter}
                leaderBoardInfo={leaderBoardInfo}
                isFinished={isFinished}
                koinsLeaderboardHeaders={koinsLeaderboardHeaders}
                dynamicHeaders={dynamicHeaders}
                isMobile={isMobile}
                gamePlatform={gamePlatform}
                gameId={gameId}
                joinMode={joinMode}
                winnersPrize={winnersPrize}
                selectPlayerForTemplate={selectPlayerForTemplate}
                hasTemplate={template && Object.keys(template)?.length > 0}
                template={template}
                winnerSectionBg={winnerSectionBg}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                poolStatus={status}
                poolId={poolId}
                groupIdSelected={groupIdSelected}
                S
              />
            </>
          )}
        </div>
      </S.LeaderBoardContainer>

      <LeaderboardTemplateModal
        toggleModal={toggleLeaderboardTemplate}
        showModal={showLeaderboardTemplate}
        team={selectedPlayer}
        template={template}
      />
    </>
  );
}
